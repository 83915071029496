import { Checkbox } from '@material-tailwind/react'
import React from 'react'

export default function EmailNotifications() {
  return (
    <div className=' bg-white rounded-lg p-4'>
      <p>Announcements and Resources</p>
      <div className='flex flex-col'>
        <Checkbox label="Creator newsletter" nonce={undefined} onResize={undefined} onResizeCapture={undefined} enterKeyHint={undefined} />
        <Checkbox label="Creater resources" nonce={undefined} onResize={undefined} onResizeCapture={undefined} enterKeyHint={undefined} />
      </div>
      <p className=' mt-3'> Notifications</p>
      <div className='flex flex-col'>
        <Checkbox label="When you get a new creator" nonce={undefined} onResize={undefined} onResizeCapture={undefined} enterKeyHint={undefined} />
        <Checkbox label="When creator sen you message" nonce={undefined} onResize={undefined} onResizeCapture={undefined} enterKeyHint={undefined} />
        <Checkbox label="When you make a post" nonce={undefined} onResize={undefined} onResizeCapture={undefined} enterKeyHint={undefined} />
        <Checkbox label="When email to all creator when you make a pledge" nonce={undefined} onResize={undefined} onResizeCapture={undefined} enterKeyHint={undefined} />
      </div>
      <div className=' flex justify-end mt-4'>
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
        >
          Update email settings
        </button>
      </div>
    </div>
  )
}
