import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CreatorLayout from '../../../components/creator-layout/creator-layout'
import Settings from '../../../components/views/creator/settings'
export default function Creator() {
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <CreatorLayout>
          <Settings />
        </CreatorLayout>
      </DndProvider>
    </>
  )
}