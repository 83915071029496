import React from 'react'
import Table from '../../../molecules/table'


import CreatorController from "../../../../services/creator";
import { Switch } from "@material-tailwind/react";
import { toast } from 'react-hot-toast';

export default function BillingAndPayment() {
  const [currentPaymentMethods, setCurrentPaymentMethods] = React.useState([])

  const getCurrentPaymentMethods = async () => {
    const response = await CreatorController.getCurrentPaymentMethods();
    setCurrentPaymentMethods(response?.data?.result || [])
  }

  const updatePaymentMethod = async (id: string, status: number) => {
    const response = await CreatorController.updatePaymentMethod({ id, status });
    console.log(response)
    if (response?.status !== 1) {
      toast.error(response?.message)
      return
    }
    toast.success("Update Success")
    getCurrentPaymentMethods()
  }

  React.useEffect(() => {
    getCurrentPaymentMethods()
  }, [])

  return (
    <div>
      <div className='bg-white rounded-lg p-4 mt-6 text-gray-500'>
        <div className=' font-medium text-sm text-black'> Billing Schedule</div>
        <p className='mt-2 text-xs'> Patron sare changed on the day they join your membership, and then monthly on that same date. For example, if the patron joins on the 10th, they will be change on that day, and then monthly on the 10th, All patron who joined before your switch to subscription billing continue to be changed on the 1st of each month. </p>
      </div>

      <div className='bg-white rounded-lg p-4 mt-6 text-gray-500'>
        <div className=' font-medium text-sm text-black'> Payout Method</div>
        <p className='mt-2 text-xs'>Add a payout method paid!</p>
        <button className='mt-3 w-fit h-fit px-3 py-2 rounded-lg font-medium bg-blue-100 text-blue-500'>Add payout method</button>
      </div>

      <div className='bg-white rounded-lg p-4 mt-6 text-gray-500'>
        <div className=' font-medium text-sm text-black'>Current Payment Methods</div>
        {/* <p className='mt-2 text-xs'>Add a payout method paid!</p> */}
        {/* <button className='mt-3 w-fit h-fit px-3 py-2 rounded-lg font-medium bg-blue-100 text-blue-500'>Add payout method</button> */}
        <Table>
          <Table.Head>
            <Table.HeadRow className="text-grey-50 inter-small-semibold">
              <Table.HeadCell>Payment Method</Table.HeadCell>

              <Table.HeadCell>Transaction Fee</Table.HeadCell>
              <Table.HeadCell>Reservation</Table.HeadCell>

              <Table.HeadCell>Unsigned in month</Table.HeadCell>

              <Table.HeadCell>Status</Table.HeadCell>

            </Table.HeadRow>

          </Table.Head>

          <Table.Body>
            {
              currentPaymentMethods.map((method: any, index: number) => (
                <Table.Row key={index}>
                  <Table.Cell>{
                    method?.paymentMethod?.name
                  }</Table.Cell>
                  <Table.Cell>{
                    method?.paymentMethod?.transactionFee
                  }</Table.Cell>
                  <Table.Cell>{
                    method?.reservation
                  }</Table.Cell>
                  <Table.Cell>{
                    method?.unsignedInMonth
                  }</Table.Cell>
                  <Table.Cell>
                    <Switch id={method?.id} color={method?.status === 2 ? "red" : "green"} checked={method?.status === 1 && method.paymentMethod.status === 1} onChange={(e) => {
                      updatePaymentMethod(method.id, e.target.checked ? 1 : 0)
                    }} ripple={false} />
                  </Table.Cell>

                </Table.Row>
              ))
            }
            <Table.Row>

            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}
