import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'


export default function Account() {
  const [isShowPlan, setShowPlan] = useState(false)
  const [isOpenPauseBilling, setOpenPauseBilling] = useState(false)
  return (
    <div>
      <div className='bg-white rounded-lg p-4 text-gray-500'>
        <div className='text-gray-600'>YOUR PLAN</div>
        {!isShowPlan && <div className=' flex justify-between'>
          <div className=' text-xs'>
            <div className=' text-base font-me my-2 text-black'>Pro</div>
            <p>8% All the tool to master membership</p>
            <p>Includes video uploads <span className='text-xs text-blue-400'>{`(early access only)`}</span></p>
          </div>
          <button onClick={() => setShowPlan(true)} className=' w-fit h-fit px-3 py-2 rounded-lg font-medium bg-blue-100 text-blue-500'>Change Plan</button>
        </div>}
        {isShowPlan &&
          <div >
            <div className=' flex justify-between'>
              <div className=' text-xs'>
                <div className=' text-base font-me my-2 text-black'>Lite</div>
                <p>5% simple tools to setuprecurring support from your fans</p>
                <p>Includes video uploads <span className='text-xs text-blue-400'>{`(early access only)`}</span></p>
              </div>
              <button className=' w-fit h-fit px-3 py-2 rounded-lg font-medium bg-blue-100 text-blue-500'>Downgrade</button>
            </div>
            <div className=' flex justify-between mt-3'>
              <div className=' text-xs'>
                <div className=' text-base font-me my-2 text-black'>Pro <span className='border border-gray-300 rounded-lg text-xs p-1 ml-2 text-gray-300'>Your plan</span></div>
                <p>8% All the tool to master membership</p>
                <p>Includes video uploads <span className='text-xs text-blue-400'>{`(early access only)`}</span></p>
              </div>
              <button className=' w-fit h-fit px-3 py-2 rounded-lg font-medium bg-blue-100 text-blue-500'>Current Plan</button>
            </div>
            <div className=' flex justify-between mt-3'>
              <div className=' text-xs'>
                <div className=' text-base font-me my-2 text-black'>Pro + Merch</div>
                <p>11% All the tool to master membership plus Merch for Membership</p>
                <p>Includes video uploads <span className='text-xs text-blue-400'>{`(early access only)`}</span></p>
              </div>
              <button className=' w-fit h-fit px-3 py-2 rounded-lg font-medium  text-blue-500'> Learn more</button>
            </div>
            <div className=' flex justify-between mt-3'>
              <div className=' text-xs'>
                <div className=' text-base font-me my-2 text-black'>Premium</div>
                <p>12% for established creators and creator bussinesses</p>
                <p>Includes video uploads <span className='text-xs text-blue-400'>{`(early access only)`}</span></p>
              </div>
              <button className=' w-fit h-fit px-3 py-2 rounded-lg font-medium bg-blue-200 text-blue-500'> Apply</button>
            </div>
            <p className='text-xs text-blue-400 mt-3'>Patreon's plans and features {'>'}</p>
          </div>}
      </div>

      <div className='bg-white rounded-lg p-4 mt-6 text-gray-500'>
        <div className='text-gray-600'>PAYMENT PROGESSING RATE*</div>
        <div className=' flex gap-20 mt-3'>
          <div>
            <div className='text-gray-600 text-xs'>PAYMENT AMOUNT</div>
            <div className='text-xs ml-2 mt-4 flex flex-col gap-4'>
              <p>$3 or less</p>
              <p>over $3</p>
            </div>
          </div>
          <div>
            <div className='text-gray-600 text-xs'>RATE PER SUCCESSFULL PAYMENT</div>
            <div className='text-xs ml-2 mt-4 flex flex-col gap-4'>
              <p>5% + $0.10</p>
              <p>2.9% + 0.30</p>
            </div>
          </div>
        </div>
        <p className='mt-4 text-xs'>Additional 1% for non US Paypal payments</p>
        <p className='mt-2 text-xs'>*this rate applies to the full amount of the pledge, including any tax amounts applied</p>
      </div>

      <div className='bg-white rounded-lg p-4 mt-6 text-gray-500'>
        <div className='text-gray-600'>VIDEO STATUS</div>
        <div className=' font-medium text-base mt-3 text-black'>Not eligible yet</div>
        <p className='mt-2 text-xs'>Upload vidoe to Patreon is currently only available to creator with early access <span className='text-blue-400'>Learn more</span></p>
      </div>

      <div className='bg-white rounded-lg p-4 mt-6 text-gray-500'>
        <div className='text-gray-600'>ACCOUNT MANAGERMENT</div>
        <div className=' font-medium text-sm mt-3 text-black'>Pause billing</div>
        <p className='mt-2 text-xs'> Need a break? Patreon will not change your creators for one month starting from the day your pause. New creators will not be able to sign up during this time.Billing automatically resumes on/around the same calendar day the following month. <span className='text-blue-400'>Learn more</span></p>
        <button onClick={() => setOpenPauseBilling(true)} className='mt-3 w-fit h-fit px-3 py-2 rounded-lg font-medium bg-blue-100 text-blue-500'>Pause billing</button>
      </div>

      <Transition appear show={isOpenPauseBilling} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setOpenPauseBilling(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    PAUSE BILLING
                  </Dialog.Title>
                  <div className=" text-xs mt-4">
                    <p className="text-sm text-gray-500">
                      Confirm that you would like to pause billing for one month starting March. Billing will resume automatically on April 29
                      <ul className='ml-4 '>
                        <li className='mt-2'>Creators will not be able to sign up during this time</li>
                        <li className='mt-2'>If you unpause before the date billling resumes, you won't be able to go back and and change creators who billings date was skipped while you were paused</li>
                        <li className='mt-2'>If you would like to pause for more than month, please repeat this prosess after April 29</li>
                      </ul>
                      <p className='mt-3'><span className=' text-blue-400'>Learn more</span> about pausing billing</p>
                    </p>
                  </div>

                  <div className="mt-4 flex gap-4 justify-end">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-blue-500"
                      onClick={() => setOpenPauseBilling(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => setOpenPauseBilling(false)}
                    >
                      Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}
