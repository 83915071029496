import React from 'react'
import TeamImg from '../../../../assets/team.jpg'

export default function Team() {
  return (
    <div className='p-6'>
      <div className=' text-xl font-semibold mt-6'>Your Team</div>
      <div className=' flex justify-center gap-16 items-center mt-10'>
        <img className='rounded-2xl w-[300px]' src={TeamImg} />
        <div className='max-w-sm'>
          <div className=' text-lg font-medium'>Do you have a team?</div>
          <p>Upgrade to "Premium" and give multiple teammates access to run your Patreon account</p>
          <button className='mt-3 w-fit h-fit px-3 py-2 rounded-lg font-medium bg-blue-100 text-blue-500'>Upgrade your plan</button>
        </div>
      </div>
    </div>
  )
}
